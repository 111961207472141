import isAValidEmail from './isAValidEmail';

const filterAndMapEmails = (contactInfo) => {
  const { work_email, other_email, email: mainEmail } = contactInfo;
  const possibleEmails = [mainEmail, work_email, other_email];
  const filteredEmails = possibleEmails.filter((email) => email);

  return filteredEmails.map((email, index) => ({
    value: email,
    active: index === 0,
  }));
};

const createContact = {
  suggestion: {
    email: (suggestion) => {
      const { id, name, contact_info: contactInfo } = suggestion;
      const emails = filterAndMapEmails(contactInfo);
      const isValid = emails.length > 0;
      const isRaw = false;
      const checkedIfExists = true;
      const validatedOnTwilio = false;

      return {
        id,
        name,
        emails,
        isValid,
        isRaw,
        checkedIfExists,
        validatedOnTwilio,
      };
    },
    whatsapp: (suggestion) => {
      const { id, name, contact_info: contactInfo } = suggestion;
      const cellphone = contactInfo?.cellphone
        ? contactInfo.cellphone.trim()
        : null;
      const isValid = true;
      const isRaw = false;
      const checkedIfExists = true;
      const validatedOnTwilio = false;

      return {
        id,
        name,
        emails: [],
        isValid,
        isRaw,
        cellphone,
        checkedIfExists,
        validatedOnTwilio,
      };
    },
  },
  raw: {
    email: (input) => {
      const trimmedInput = input.trim();
      const isValid = isAValidEmail(trimmedInput);
      const isRaw = true;
      const checkedIfExists = !isValid;
      const validatedOnTwilio = false;

      return {
        id: trimmedInput,
        name: trimmedInput,
        emails: [{ value: trimmedInput, active: true }],
        isValid,
        isRaw,
        checkedIfExists,
        validatedOnTwilio,
      };
    },
    whatsapp: (number, fieldsToOverride = {}) => {
      const trimmedNumber = number.trim();
      const id = trimmedNumber;
      const name = trimmedNumber;
      const cellphone = trimmedNumber;
      const isValid = true;
      const isRaw = true;
      const validatedOnTwilio = true;
      const checkedIfExists = false;

      return {
        id,
        name,
        emails: [],
        isValid,
        isRaw,
        cellphone,
        checkedIfExists,
        validatedOnTwilio,
        ...fieldsToOverride,
      };
    },
  },
  related: (relatedContact) => {
    const { contact_related, relation } = relatedContact;
    const { id, name: plainRelatedName, ...contactInfo } = contact_related;

    const emails = filterAndMapEmails(contactInfo);
    const isValid = emails.length > 0;
    const isRaw = false;
    const checkedIfExists = true;
    const validatedOnTwilio = false;
    const name = relation
      ? `${plainRelatedName} (${relation})`
      : plainRelatedName;

    return {
      id,
      name,
      emails,
      isValid,
      isRaw,
      checkedIfExists,
      validatedOnTwilio,
    };
  },
  new: (newContact) => {
    const { email, id, name } = newContact;
    const cellphone = newContact?.cellphone
      ? newContact.cellphone.trim()
      : null;

    const emails = email ? [{ value: email, active: true }] : [];
    const isValid = true;
    const isRaw = false;
    const checkedIfExists = true;
    const validatedOnTwilio = true;

    return {
      id,
      name,
      emails,
      isValid,
      isRaw,
      cellphone,
      checkedIfExists,
      validatedOnTwilio,
    };
  },
};

export default createContact;
